<template>
  <div class="routerTabComponent">
    <div class="item" :class="{active: active(item.fullPath, currentTab)}" v-for="item in tabList" :key="Math.random()" @click="handleTabClick(item)">
      <div class="content">
        <div class="text">{{item.name}}</div>
        <i class="el-icon-close close"  v-if="item.fullPath != '/home'" @click.stop="handleTabClose(item)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { _comparePath } from '@/utils/utils'
export default {
  props: {
    currentTab: {
      type: String,
      default: ''
    },
    tabList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    //点击
    handleTabClick(item) {
      if (item.fullPath == this.currentTab) return
      this.$emit('tabChange', item)
    },
    //删除
    handleTabClose(item) {
      this.$emit('tabRemove', item)
    },
    //是父子路由关系也算true
    active(path1, path2) {
      try {
        return _comparePath(path1, path2)
      } catch (error) {

      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.routerTabComponent {
  width: 100%;
  height: 45px;
  display: flex;
  background: white;
  align-items: center;
  border-bottom: 1px solid rgb(239, 239, 239);
  padding-left: 8px;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    color: #888888;
    background: #fff;
    padding: 0 8px;
    font-size: 12px;
    .content {
      display: flex;
      align-items: center;
      .text {
        padding: 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .close {
        margin-right: 6px;
        font-size: 12px;
      }
    }
  }
  .active {
    color: $primary !important;
    &::after {
      content: '';
      position: absolute;
      bottom: -3px;
      height: 2px;
      width: 100%;
      color: $primary;
      background-color: $primary;
    }
  }
}
</style>