<template>
  <div class="changePasswordCmpt">
    <el-dialog :visible.sync="pwdDialogShow" width="620px" :close-on-click-modal="false">
      <span slot="title">修改密码</span>
      <div class="box">
        <div class="title">
          您所归属的企业已设置您的登录手机号为：{{ showNumber }}
        </div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item prop="pass" label="新密码" required>
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off" clearable placeholder="请输入新密码"></el-input>
          </el-form-item>

          <el-form-item prop="checkPass" label="确认密码" required>
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" clearable placeholder="请再次输入新密码"></el-input>
          </el-form-item>

          <el-form-item prop="code" label="验证码" required>
            <el-input style="width:70%" v-model="ruleForm.code" auto-complete="off" clearable placeholder="请输入验证码">
            </el-input>
            <el-button class="btn-send" type="primary" @click="getVerifyCode" :disabled="!verifyCooling">
              {{
                verifyCoolingTime == 0 || verifyCoolingTime == 60
                  ? "发送验证码"
                  : verifyCoolingTime + "s后重新获取"
              }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPwd('ruleForm')">确认修改</el-button>
        <el-button @click="closedialog('ruleForm')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { resetPassword } from "@/api/personalCenter/resetPwd";
import { mapGetters } from "vuex";
import verify from '@/utils/verify'
import { sendCode } from '@/api/register'
import md5 from "js-md5";
export default {
  data() {
    let code = (rule, value, callback) => {
      if (!/^\d{6}$/.test(value)) {
        return callback(new Error("请输入6位数验证码"));
      } else {
        callback();
      }
    }; 
    let validatePass = (rule, value, callback) => {
      if (!/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(value)) {
        callback(new Error("密码中必须包含字母,数字,长度不能少于6位"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      showNumber: "", // 用于修改密码展示的手机号
      pwdDialogShow: false, // 修改密码弹框
      verifyCooling: true, //是否能按下发送验证码按钮
      verifyCoolingTime: 60, //剩下多少秒才能发送验证码
      // 修改密码表单验证
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        code: [{ validator: code, trigger: "blur" }],
      },
      ruleForm: {
        phone: "",
        pass: "",
        checkPass: "",
        code: "",
      }, // 修改密码表单
    }
  },
  methods: {
    // 修改密码
    resetPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(.{6,})|(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])(?=.*[`~@!#$%^&*()_\-+=<>?:\"{}|,.\/;'\\[\]])(.{6,})$/.test(this.ruleForm.pass)) {
            this.$message.error('密码中必须包含字母,数字,长度不能少于6位')
            return
          }
          const params = {
            phone: this.ruleForm.phone,
            password: md5(this.ruleForm.pass),
            code: this.ruleForm.code
          }
          resetPassword(params).then(res => {
            //修改成功隐藏弹框
            this.pwdDialogShow = false
          })
        }
      });
    },
    // 间隔手机号码
    setPhoneNumber() {
      this.ruleForm.phone = this.getPhone;
      this.showNumber = this.getPhone.substring(0, 3) + "****" + this.getPhone.substring(7, 11);
    },
    //发送验证码
    getVerifyCode() {
      //正在倒计时中直接return
      if (!this.verifyCooling) return;
      this.verifyCooling = false;
      this.verifyCoolingTime = this.verifyCoolingTime - 1;
      //倒计时60S才能再次发送验证码
      let timer = setInterval(() => {
        this.verifyCoolingTime = this.verifyCoolingTime - 1;
        if (this.verifyCoolingTime === 0) {
          this.verifyCooling = true;
          this.verifyCoolingTime = 60;
          clearInterval(timer);
        }
      }, 1000);
      // 发送验证码
      const params = {
        phone: this.ruleForm.phone
      }
      sendCode(params).then(res => {
        if (res.code === 0) {
          this.$message.success('验证码发送成功')
        }
      })
    },
    closedialog(formName) {
      this.pwdDialogShow = false;
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.setPhoneNumber();
  },
  computed: {
    ...mapGetters(["getPhone"]),
  },
}
</script>

<style lang="scss" scoped>
.changePasswordCmpt {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btn-send {
    margin-left: 2%;
    text-align: center;
    font-size: 14px;
  }
  .title {
    // margin-bottom: 30px;
    text-align: center;
    padding-bottom: 20px;
    // border-bottom: 1px solid #999999;
  }
}
</style>