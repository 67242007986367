<template>
  <div class="personalInfo" v-show="isShow">
    <div class="box">
      <div class="title">
        <div class="name">个人信息</div>
        <i class="el-icon-close fix-right" @click="isShow = false"></i>
      </div>
      <div class="warn">
        <el-alert title="温馨提示：" type="warning" description="您的个人信息由您归属企业的管理员加入，如需修改，请联系管理员在“人员管理”中编辑" :closable="false">
        </el-alert>
      </div>
      <div class="info">
        <div class="part">
          <div class="name">手机号码</div>
          <div class="input">{{getPhone ? getPhone : '-'}}</div>
        </div>
        <div class="part">
          <div class="name">身份证号</div>
          <div class="input">{{getIdCard != 'null' ? getIdCard : '-'}}</div>
        </div>
        <div class="part">
          <div class="name">企业名称</div>
          <div class="input">{{enterpriseName}}</div>
        </div>
        <div class="part">
          <div class="name">姓名</div>
          <div class="input">{{getName != 'null' ? getName : '-'}}</div>
        </div>
        <div class="part">
          <div class="name">邀请码</div>
          <div class="input">{{getUserId ? getUserId : '-'}}</div>
        </div>
      </div>
      <!-- <div class="btn-box">
        <div class="btn" @click="isShow = false">关闭</div>
      </div> -->

    </div>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import { mapGetters } from "vuex";
import { _getEnterpriseName } from '@/utils/storage'
export default {
  mixins: [basicMixin],
  data() {
    return {
      isShow: false, // 显示组件
      enterpriseName: '', //企业名称
    };
  },
  computed: {
    ...mapGetters(["getName", "getPhone", "getIdCard", "getUserId"]),
  },
  methods: {

  },
  mounted() {

  },
  created() {
    this.enterpriseName = _getEnterpriseName()
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  position: absolute;
  top: 7%;
  right: 1%;
  background: #fff;
  padding: 20px 14px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 22%;
  z-index: 111111;
  min-width: 380px;

  .box {
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #333;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .fix-right {
        position: absolute;
        top: 5px;
        right: 0;
        cursor: pointer;
      }
    }
    .warn {
      margin-top: 8px;
      color: #ff2323;
      font-size: 16px;
      .part2 {
        margin-top: 4px;
        padding-bottom: 16px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 16px;
      }
    }
    .info {
      color: #333333;
      margin-top: 10px;
      .part {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .name {
          font-size: 14px;
          margin-right: 8px;
          white-space: nowrap;
          width: 84px;
          text-align: right;
          //  font-weight: bold;
        }
        .input {
          background: #f5f5f5;
          border-radius: 6px;
          width: 100%;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      .btn {
        background: #2081ff;
        padding: 5px 24px;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;
        color: #fff;
      }
    }
  }
}
</style>