<template>
  <div class="recordMsg" v-show="isShow">
    <div class="bg" @click="isShow = false"></div>
    <div class="box">
      <div style="width: 100%;height: 30px;line-height:30px; text-align: center;padding-bottom:10px; border-bottom: 1px solid #f5f5f5">
        <div class="name">重要记录</div>
        <div class="btn"  @click="isShow = false"><i class="el-icon-close"></i></div>
      </div>
      <el-alert style="margin:10px 0px;"
        type="warning" :closable="false"
        title=" 温馨提示：系统与警告通知存入重要记录，永久保存。"
      >
      </el-alert>
      <div class="item_box">
        <div class="item" v-for="item in msgList" :key="item.ID">
          <div class="inner">
          <div class="top">
            <span>操作账号：{{item.Account}}</span>
            <span>用户姓名：{{item.UserName}}</span>
            <span>身份证号：{{item.IDCard}}</span>
          </div>
          <div class="content">
            <div class="title">操作内容：</div>
            <div v-html="item.Body"></div>
            </div>
          <div class="bottom">
            <span class="title">操作时间：</span>{{item.CreateDate}}
          </div>
          </div>
        </div>
        <el-empty :image-size="200" v-if="msgList.length == 0"></el-empty>
      </div>
      
       <div class="btn-bottom">
          
          <div class="page" v-if="msgList.length > 0">
            <!-- <div class="btn1" style="margin-right: 10px;" @click="toOnePage"><i class="el-icon-d-arrow-left"></i></div>
            <div class="btn1" @click="prePage"><i class="el-icon-arrow-left"></i></div>
            <div class="num">{{pageIndex}}/{{totalPageNum}}</div>
            <div class="btn2" @click="nextPage"><i class="el-icon-arrow-right"></i></div>
            <div class="btn1" style="margin-left: 10px;" @click="toLastPage"><i class="el-icon-d-arrow-right"></i></div> -->
            <el-pagination background
            small
            layout="prev, pager, next"
             @current-change="handleCurrentChange"
            :current-page.sync="pageIndex"
            :page-size="pageSize"
            :total="totalPage">
          </el-pagination>
          </div>
        </div>
    </div> 
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import {GetRecordslist } from "@/api/home/home";
import { _getParentRoleId } from "@/utils/storage";
export default {
  mixins: [basicMixin],
  data() {
    return {
      pageSize: 3, // 消息分页
      pageIndex: 1, // 消息分页
      isShow: false,
      totalPage: 0, // 消息总条数
      totalPageNum: 1, // 消息总页数
      msgList: [] // 操作记录列表
    };
  },
  methods: {
    // 点击按钮时获取重要记录   queryVital 重要通知  queryComple 为首页是否强制提示  传给recordMsg组件
    GetRecordslist() {
      let params = {
        queryVital: true,
        queryComple: false,
        pageSize: this.pageSize, 
        pageIndex: this.pageIndex 
      };
      GetRecordslist(params).then((res) => {
        this.msgList = res.data.DataList
        this.totalPage = res.data.TotalCount
        this.totalPageNum = Math.ceil(Number(res.data.TotalCount / this.pageSize))
      });
    },
     //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pageIndex = e
      this.GetRecordslist();
    },
    // // 上一页
    // prePage() {
    //   if (this.pageIndex <= 1) return
    //     this.pageIndex--;
    //     this.GetRecordslist();
    // },
    // // 下一页
    // nextPage() {
    //   if (this.pageIndex * this.pageSize >= this.totalPage) return
    //     this.pageIndex++;
    //     this.GetRecordslist();
    // },
    // // 去第一页
    // toOnePage() {
    //   this.pageIndex = 1
    //   this.GetRecordslist();
    // },
    // // 去最后一页
    // toLastPage(){
    //   this.pageIndex = Math.ceil(this.totalPage/this.pageSize)
    //   this.GetRecordslist();
    // }
  },
  mounted() {
    //this.GetRecordslist()
  },
};
</script>

<style lang="scss" scoped>
.recordMsg {
  .bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
  }
  .box {
    width: 50%;
    background: #fff;
    height: 75vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    z-index: 9999999;
    border-radius: 12px;
    padding: 20px;
    .el-alert{
      overflow: initial;
    }
    .item_box{
      height: 60vh;
      overflow: auto;
      padding: 10px 0px;
      width: 100%;
      box-sizing: border-box;
      .item {
        // box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.13);
        border: 1px solid #f0f0f0;
        border-radius: 12px;
        background: #fff;
        padding: 16px 24px;
        width: 92%;
        margin-bottom: 16px;
        color: #333333;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        .inner {
          .title {
            white-space: nowrap;
          }
        .top {
          margin-bottom: 4px;
          span {
            margin-right: 20px;
          }
        }
          .content {
            display: flex;
            margin-bottom: 4px;
          }
        }
        
      }
    }
    .item_box::-webkit-scrollbar{
      width: 6px;
    }
    .item_box::-webkit-scrollbar-thumb {
      background: #ddd;
    }
    .btn {
        cursor: pointer;
        position: absolute;
        right: 32px;
        top: 24px;
        i {
          font-size: 18px;
          color: #999999;
        }
        span {
          background: #2081FF;
          padding: 5px 34px;
          box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
          color: #fff;
          border-radius: 4px;
          
        }
      }
    .name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .tip {
      margin-bottom: 20px;
    }
    
  }
  .btn-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    .page {
      display: flex;
      align-items: center;
    }
    .num {
      color: #999;
      margin: 0 8px;
    }
    .btn1 {
      background: #fff;
      padding: 6px 12px;
      border: 1px solid #CBCBCB;
      cursor: pointer;
      white-space: nowrap;
      color: #999999;
    }
    .btn2 {
      background: #fff;
      padding: 6px 12px;
      border: 1px solid #CBCBCB;
      cursor: pointer;
      white-space: nowrap;
      color: #999999;
    }
    
  }
}
</style>