var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Msg"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.total == 0),expression:"total == 0"}],staticClass:"msgBox"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v(" 消息提醒 ")]),_c('div',{staticClass:"tip"},[_vm._v("您有一条新的消息，请注意查收！")]),_c('div',{staticClass:"btn"},[_c('div',{staticClass:"look",on:{"click":_vm.showMsg}},[_vm._v("查看")]),_c('div',{staticClass:"ignore",on:{"click":_vm.ignoreMsg}},[_vm._v("忽略")])])])]),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMsgBox),expression:"showMsgBox"}],staticClass:"messageBox"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_c('span',{staticStyle:{"font-size":"14px","color":"#777777","font-weight":"500"}},[_vm._v("消息提醒")]),_c('i',{staticClass:"el-icon-close",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showMsgBox = false}}})]),_c('el-button',{staticClass:"read-btn",attrs:{"size":"mini","type":"primary"},on:{"click":_vm.readAll}},[_vm._v("全部已读")])],1),_c('div',{staticClass:"msgList"},[_vm._l((_vm.msgList),function(item){return _c('div',{key:item.MsgID,staticStyle:{"padding-bottom":"16px","border-bottom":"1px solid #f5f5f5","cursor":"pointer"},on:{"click":function($event){return _vm.handleChange(item.MsgID, item)}}},[_c('div',[_c('div',{style:(item.IsRead == false
                ? 'display: flex;  margin-top: 8px; align-items: center;justify-content: space-between;'
                : 'display: flex; color: gray;margin-top: 8px; align-items: center;justify-content: space-between;')},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-tag',{attrs:{"size":"small","type":item.MsgTypeID == 1
            ? ''
            : item.MsgTypeID == 2
              ? 'warning'
              : item.MsgTypeID == 3
                ? 'success'
                : 'danger'}},[_vm._v(_vm._s(item.MsgTypeName))])],1),_c('div',[_c('span',{staticStyle:{"margin":"2px 0 0 4px","color":"#CBCBCB"}},[_vm._v(_vm._s(item.CreateDate))])])]),_c('div',{staticStyle:{"margin-top":"8px","font-size":"12px","font-weight":"400"},style:(item.IsRead == false
                ? ''
                : 'color: gray')},[_vm._v(_vm._s(item.msg)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.isMore),expression:"item.isMore"}],staticStyle:{"color":"#2081FF"}},[_vm._v("更多")])])])])}),(_vm.totalPageNum == 0)?_c('el-empty',{attrs:{"image-size":200}}):_vm._e()],2),(_vm.totalPageNum != 0)?_c('div',{staticClass:"bottom"},[_c('el-pagination',{attrs:{"background":"","small":"","layout":"prev, pager, next","current-page":_vm.pageIndex,"page-size":_vm.pageSize,"total":_vm.totalPage},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.pageIndex=$event},"update:current-page":function($event){_vm.pageIndex=$event}}})],1):_vm._e(),_c('audio',{ref:"audio",attrs:{"id":"audio","preload":"auto","src":_vm.ossHost + 'msg.mp3'}})])]),_c('el-dialog',{staticClass:"deep_dialog",attrs:{"title":_vm.msgInfo.MsgTypeName,"visible":_vm.dialogMsg,"width":"30%"},on:{"update:visible":function($event){_vm.dialogMsg=$event}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.msgInfo.MsgContent)}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.msgInfo.Url)?_c('div',{staticClass:"msg-tips"},[_vm._v("点击立即前往即可快捷到达该业务指定页面")]):_vm._e(),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.dialogMsg = false}}},[_vm._v("取 消")]),(_vm.msgInfo.Url)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.gotoPage(_vm.msgInfo.Url)}}},[_vm._v("立即前往")]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }