<template>
  <div class="changePasswordCmpt">
    <el-dialog :visible.sync="safeDialogShow" width="800px" :close-on-click-modal="false">
      <span slot="title">安全中心</span>
      <el-tabs v-model="activeName">
        <el-tab-pane label="密钥配置" name="1"></el-tab-pane>
        <el-tab-pane label="回调配置" name="2"></el-tab-pane>
      </el-tabs>
      <template v-if="activeName == 1">
        <div class="box">
          <div class="title">
            温馨提示：点击“生成密钥”按钮，获得您的企业密钥，用于对接“运服通外联平台”
          </div>
        </div>
        <el-form status-icon ref="ruleForm" label-width="60px">
          <el-form-item>
            <el-input style="width:70%; margin-right: 10px" id="copyCode" v-model="codeInfo.PublicKey" auto-complete="off"
              readonly placeholder="点击按钮生成秘钥">
            </el-input>
            <el-button class="btn-send" type="primary" size="mini" @click="createSecretCode" :disabled="loading">
              生成密钥
            </el-button>
            <el-button class="btn-send" type="primary" size="mini" @click="copySecretCode" :disabled="loading">
              复制密钥
            </el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <el-form status-icon ref="ruleForm1" label-width="150px">
          <el-form-item label="是否开启通知：">
            <el-switch v-model="codeInfo.IsCallBack" active-text="是" inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="风控回调地址：">
            <el-input style="width:75%;" v-model="codeInfo.CallBackRisk" auto-complete="off" placeholder="风控回调地址">
            </el-input>
          </el-form-item>
          <el-form-item label="司机状态回调地址：">
            <el-input style="width:75%;" v-model="codeInfo.CallBackDriver" auto-complete="off" placeholder="司机状态回调地址">
            </el-input>
          </el-form-item>
          <el-form-item label="车辆状态回调地址：">
            <el-input style="width:75%;" v-model="codeInfo.CallBackCar" auto-complete="off" placeholder="车辆状态回调地址">
            </el-input>
          </el-form-item>
          <el-form-item label="开票完成回调地址：">
            <el-input style="width:75%;" v-model="codeInfo.CallBackInvoice" auto-complete="off" placeholder="开票完成回调地址">
            </el-input>
          </el-form-item>
        </el-form>
        <div style=" display: flex;align-items: center;justify-content: center; ">
          <el-button type="primary" @click="editUserKeyNotify">保存配置</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getSecretCode, createSecretCode, EditUserKeyNotify } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  data() {

    return {
      activeName: '1',
      codeInfo: {},
      safeDialogShow: false, // 安全中心弹框
      loading: false, // 按钮加载

    }
  },
  methods: {
    // 生成秘钥
    createSecretCode() {
      this.loading = true
      createSecretCode().then(res => {
        this.codeInfo.PublicKey = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    // 复制密钥
    copySecretCode() {
      document.getElementById('copyCode').select();
      document.execCommand('copy');
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    // 获取秘钥
    getSecretCode() {
      this.loading = true
      getSecretCode().then(res => {
        this.codeInfo = res.data || {}
      }).finally(() => {
        this.loading = false;
      })
    },
    // 保存通知配置
    editUserKeyNotify() {
      this.loading = true
      EditUserKeyNotify(this.codeInfo).then(res => {
        this.$message.success("保存成功")
        this.getSecretCode()
      }).finally(() => {
        this.loading = false;
      })
    },
    closedialog(formName) {
      this.safeDialogShow = false;
      this.$refs[formName].resetFields();
    }
  },
  mounted() {
    this.getSecretCode()
  },
  computed: {
    ...mapGetters(["getPhone"]),
  },
}
</script>

<style lang="scss" scoped>
.changePasswordCmpt {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .btn-send {
    margin-left: 2%;
    text-align: center;
    font-size: 14px;
  }

  .title {
    // margin-bottom: 30px;
    text-align: center;
    padding-bottom: 20px;
    // border-bottom: 1px solid #999999;
  }
}
</style>