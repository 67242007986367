<template>
  <div class="Msg">
    <div class="msgBox" v-show="total == 0">
      <div class="box">
        <div class="title">
          消息提醒
        </div>
        <div class="tip">您有一条新的消息，请注意查收！</div>
        <div class="btn">
          <div class="look" @click="showMsg">查看</div>
          <div class="ignore" @click="ignoreMsg">忽略</div>
        </div>
      </div>
    </div>
    <transition name="el-zoom-in-top">
      <div class="messageBox" v-show="showMsgBox">
        <div class="top">
          <div class="title">
            <span style="font-size: 14px; color: #777777; font-weight: 500;">消息提醒</span>
            <i class="el-icon-close" style="cursor: pointer" @click="showMsgBox = false"></i>
          </div>
          <el-button size="mini" type="primary" class="read-btn" @click="readAll">全部已读</el-button>
          <!-- <div class="tips">温馨提示：</div>
          <div class="tips">
            单击未读通知，将进入已读中；系统仅保留过去1年的通知消息.
          </div> -->
          <!-- <div class="tab">
          <div class="tab-item" @click="tabChange(item.id)" v-for="item in readTab" :key="item.id"><span :class="msgTableIndex ==item.id ? 'circle active' : 'circle'"></span>{{item.name}}</div>
        </div> -->
        </div>

        <div class="msgList">
          <div style="padding-bottom: 16px; border-bottom: 1px solid #f5f5f5; cursor: pointer;"
            @click="handleChange(item.MsgID, item)" v-for="item in msgList" :key="item.MsgID">
            <div>
              <div :style="
                item.IsRead == false
                  ? 'display: flex;  margin-top: 8px; align-items: center;justify-content: space-between;'
                  : 'display: flex; color: gray;margin-top: 8px; align-items: center;justify-content: space-between;'
              ">
                <div style="display: flex;align-items: center; ">
                  <!-- <img
                    style="
                      width: 16px;
                      height: 16px;
                      margin-right: 4px;
                      vertical-align: middle;
                    "
                    :src="item.MsgTypeIconUrl"
                  /><span
                    :style="
                      item.MsgTypeID == 1
                        ? 'color: #2081FF;'
                        : item.MsgTypeID == 2
                        ? 'color: #FFC117;'
                        : item.MsgTypeID == 3
                        ? 'color: #00E855'
                        : 'color: #FF2323;'
                    "
                    >{{ item.MsgTypeName }}</span
                  > -->
                  <el-tag size="small" :type="item.MsgTypeID == 1
              ? ''
              : item.MsgTypeID == 2
                ? 'warning'
                : item.MsgTypeID == 3
                  ? 'success'
                  : 'danger'">{{ item.MsgTypeName }}</el-tag>
                </div>
                <div>
                  <span style="margin:2px 0 0 4px; color: #CBCBCB;">{{ item.CreateDate }}</span>
                </div>

              </div>
              <div style="margin-top: 8px; font-size: 12px; font-weight: 400;" :style="
                item.IsRead == false
                  ? ''
                  : 'color: gray'
              ">{{ item.msg }}
                <span v-show="item.isMore" style="color: #2081FF;">更多</span>
              </div>
              <!-- <div style="display: flex; justify-content: center;">
                    <el-button size="mini" v-show="item.Url" type="primary" @click.stop="gotoPage(item.Url)">立即前往</el-button>
                  </div> -->
            </div>
            <!-- <el-collapse-transition>
                <div
                v-show="activeNames == item.MsgID"
                v-html="item.MsgContent"
              :style="item.IsRead == false ? 'margin-top: 10px;' : 'color: gray; margin-top: 10px;'"
              >
              </div>
              </el-collapse-transition> -->
          </div>
          <el-empty :image-size="200" v-if="totalPageNum == 0"></el-empty>
        </div>
        <div class="bottom" v-if="totalPageNum != 0">
          <el-pagination background small layout="prev, pager, next" @current-change="handleCurrentChange"
            :current-page.sync="pageIndex" :page-size="pageSize" :total="totalPage">
          </el-pagination>
          <!-- <div class="page">
            <div class="btn1" style="margin-right: 10px;" @click="toOnePage"><i class="el-icon-d-arrow-left"></i></div>
            <div class="btn1" @click="prePage"><i class="el-icon-arrow-left"></i></div>
            <div class="num">{{pageIndex}}/{{totalPageNum}}</div>
            <div class="btn2" @click="nextPage"><i class="el-icon-arrow-right"></i></div>
            <div class="btn1" style="margin-left: 10px;" @click="toLastPage"><i class="el-icon-d-arrow-right"></i></div>
          </div> -->
          <!-- <div class="btn3" @click="closeMsg">关闭</div> -->
        </div>
        <audio ref="audio" id="audio" preload="auto" :src="ossHost + 'msg.mp3'"></audio>
      </div>
    </transition>
    <el-dialog :title="msgInfo.MsgTypeName" :visible.sync="dialogMsg" width="30%" class="deep_dialog">
      <span v-html="msgInfo.MsgContent"></span>

      <span slot="footer" class="dialog-footer">
        <!-- <el-alert
        title="点击立即前往即可快捷到达该业务指定页面"
        type="warning" :closable="false" style="margin-bottom: 20px;" v-if="msgInfo.Url" >
      </el-alert> -->
        <div class="msg-tips" v-if="msgInfo.Url">点击立即前往即可快捷到达该业务指定页面</div>
        <el-button @click="dialogMsg = false" size="mini">取 消</el-button>
        <el-button type="primary" v-if="msgInfo.Url" size="mini" @click.stop="gotoPage(msgInfo.Url)">立即前往</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMsgList, setReadOne, GetCompleData, setReadAll } from "@/api/home/home";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      showMsgBox: false, // 展示消息列表面板
      pageSize: 5, // 消息分页
      pageIndex: 1, // 消息分页
      totalPage: 0, // 消息总条数
      totalPageNum: 0, // 消息总页数
      activeNames: "", // 展开消息面板
      msgList: [], // 消息列表
      total: 1, // 为0的时候展示有新消息提示
      dialogMsg: false,// 消息详情弹框
      msgInfo: {}, // 消息详情
    };
  },
  methods: {
    // 显示数据
    showMsg() {
      this.total = 1;
      this.getMsgList()
      this.showMsgBox = true;
      this.$emit("childMsg", "1");
    },

    // 忽略信息
    ignoreMsg() {
      this.total = 1;
      // this.$emit('childMsg','1')
    },
    //  获取是否有强制提示的信息 不分页  queryVital 重要通知  queryComple 为首页是否强制提示  传给recordMsg组件
    GetCompleData() {
      let params = {
        queryVital: false,
        queryComple: true,
      };
      GetCompleData(params).then((res) => {
        // 获取未读信息的数量
        if (res.data.length > 0) {
          this.getMsgList()
          this.showMsgBox = true
        }
      });
    },
    // 获取未读消息数量和消息列表queryVital 重要通知  queryComple 为首页是否强制提示
    getMsgList() {
      let params = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        queryVital: false,
        queryComple: false,
      };
      getMsgList(params).then((res) => {
        this.msgList = res.data.DataList
        this.totalPage = res.data.TotalCount
        if (this.totalPage == 0) {
          this.pageIndex = 0
        } else {
          this.totalPageNum = Math.ceil(Number(res.data.TotalCount / this.pageSize))
        }
        // 处理字符串 超出字符长度省略号
        this.msgList.forEach((item, index) => {
          if (item.MsgContent.length > 50) {
            item.msg1 = item.MsgContent.replace(/<div>/g, '').trim()
            item.msg2 = item.msg1.replace(/<\/div>/g, '')
            item.msg = item.msg2.substring(0, 50)
            item.isMore = true
          } else {
            item.msg1 = item.MsgContent.replace(/<div>/g, '').trim()
            item.msg = item.msg1.replace(/<\/div>/g, '')
            item.isMore = false
          }
        });
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pageIndex = e
      this.getMsgList();
    },
    // // 上一页
    // prePage() {
    //   if (this.pageIndex <= 1) return
    //     this.pageIndex--;
    //     this.getMsgList();
    // },
    // // 下一页
    // nextPage() {
    //   if (this.pageIndex * this.pageSize >= this.totalPage) return
    //     this.pageIndex++;
    //     this.getMsgList();
    // },
    // // 去第一页
    // toOnePage() {
    //   this.pageIndex = 1
    //   this.getMsgList();
    // },
    // // 去最后一页
    // toLastPage(){
    //   this.pageIndex = Math.ceil(this.totalPage/this.pageSize)
    //   this.getMsgList();
    // },
    // 播放声音
    playAudio() {
      let audioPlay = this.$refs.audio;
      audioPlay.play();
    },
    // 展开面板
    handleChange(id, item) {
      this.msgInfo = item
      this.dialogMsg = true
      this.showMsgBox = false
      if (this.activeNames == id) {
        this.activeNames = ''
      } else {
        this.activeNames = id
      }
      let params = {
        msgId: this.activeNames,
      };
      if (!params.msgId) return;
      setReadOne(params).then((res) => {
        this.msgList.forEach((element) => {
          if (element.MsgID == params.msgId) {
            element.IsRead = true;
          }
        });
      });
    },
    // 消息前往其他页面
    gotoPage(url) {
      // 如果在当前页面 就刷新页面，否则就跳转到该页面
      if (this.$route.path == url) {
        this.$router.go(0)
      } else {
        this.$router.push(url)
      }
      this.dialogMsg = false
    },
    // 全部已读
    readAll() {
      this.$confirm('是否设置为全部已读?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setReadAll({}).then((res) => {
          this.showMsgBox = false
        });
      })
    }
  },
  mounted() {
    //this.getMsgList()
    this.GetCompleData()
  },
};
</script>
<style lang="scss">
.msgList {
  .el-icon-arrow-right {
    display: none !important;
  }

  .el-alert--warning.is-light {
    color: #E6A23C !important;
  }

  .el-collapse-item__header {
    height: 100px;
    line-height: 20px;
    padding: 8px 0;
  }

  .el-button {
    padding: 6px;
  }

}
</style>
<style lang="scss" scoped>
@import "../../assets/style/variable.scss";

.msg-tips {
  background: #fdf6ec;
  color: #E6A23C;
  width: 100%;
  padding: 8px 23px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity .2s;
  margin-bottom: 20px;
  font-size: 12px;
}

.msgBox {
  position: fixed;
  bottom: 8%;
  right: 3%;
  z-index: 11111;

  .box {
    display: flex;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    background: #fff;
    flex-direction: column;
    padding: 20px;

    .title {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;

    }

    .tip {
      margin-bottom: 20px;
      font-size: 12px;
    }

    .btn {
      display: flex;
      justify-content: center;
      font-size: 8px;
      color: #fff;

      .look {
        margin-right: 30px;
        background: #2081ff;
        box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
        padding: 2px 8px;
        border-radius: 4px;
        cursor: pointer;
      }

      .ignore {
        background: #999999;
        border-radius: 4px;
        cursor: pointer;
        padding: 2px 8px;
        box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
      }
    }
  }


}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}

.messageBox {
  position: absolute;
  top: 7%;
  right: 1%;
  background: #fff;
  padding: 12px 20px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 336px;
  z-index: 1111111;

  .goPage {
    background: #2081FF;
    display: inline-block;
    padding: 0px 8px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 8px;
  }

  .top {
    display: flex;
    font-weight: bold;
    flex-direction: column;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 8px;
    position: relative;

    .read-btn {
      position: absolute;
      top: -5px;
      left: 70px;
    }

    .title {
      font-size: 24px;
      white-space: nowrap;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tips {
      color: #ff2323;
    }

    .tab {
      font-size: 14px;
      display: flex;
      white-space: nowrap;

      &-item {
        align-items: center;
        cursor: pointer;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #999999;
      margin-right: 4px;
    }

    .active {
      background: #2081ff;
      border: 1px solid #2081ff;
    }
  }

  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    color: #fff;

    .page {
      display: flex;
      align-items: center;
    }

    .num {
      color: #999;
      margin: 0 8px;
    }

    .btn1 {
      background: #fff;
      padding: 6px 12px;
      border: 1px solid #CBCBCB;
      cursor: pointer;
      white-space: nowrap;
      color: #999999;
    }

    .btn2 {
      background: #fff;
      padding: 6px 12px;
      border: 1px solid #CBCBCB;
      cursor: pointer;
      white-space: nowrap;
      color: #999999;
    }

    .btn3 {
      background: #CBCBCB;
      padding: 5px 24px;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      position: absolute;
      right: 24px;

    }
  }
}
</style>