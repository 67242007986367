import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 货主发票统计
export const InvoiceReportByHz = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataCentre/InvoiceReportByHz?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 货主司机报表
export const getDriverReport = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataCentre/DriverReport`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 货主车辆报表
export const getVehicleReport = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataCentre/VehicleReport`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 查询服务商发票统计
export const InvoiceReportByFws = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataCentre/InvoiceReportByFws?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 查询平台发票统计
export const InvoiceReportByPt = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataCentre/InvoiceReportByPt?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取消息列表 分页
export const getMsgList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/GetListByUser?`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取是否有需要强提醒的消息
export const GetCompleData = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/GetListByUserNoPage`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取重要记录
export const GetRecordslist = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/GetRecordsInfolist?`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 设置单条消息为已读
export const setReadOne = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/Read?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 设置所有消息为已读
export const setReadAll = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/ReadAll?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}