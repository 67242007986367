import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//重置密码
export const resetPassword = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/ResetPassword?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}